<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
        <v-row justify="center" class="py-5">
            <h1 class="display-1 font-weight-light">Recover access to existing account</h1>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p>If you already have an account but cannot login, follow the directions below to recover access.</p>
            </v-col>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="6" class="px-10 pb-5 pt-8" v-show="displayInputForm">
                    <v-form v-model="recoverAccessForm" ref="recoverAccessFormRef" @submit="validateRecoverAccess" onSubmit="return false;" @keyup.enter.native="validateRecoverAccess">
                        <v-text-field
                            ref="emailInputRef"
                            v-model=email
                            label="Email"
                            :rules="emailRules"
                            validate-on-blur
                            color="blue"
                            required
                            hint="What is the email address for the account?"  type="text"
                            outlined
                        >
                        <!-- v-on:keyup.enter="$event.target.nextElementSibling.focus()" -->

                    <template v-slot:prepend>
                        <font-awesome-icon icon="envelope" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        <v-row justify="center">
                            <v-card-actions>
                                <v-btn elevation="6" class="blue white--text" @click="recoverAccess" :disabled="!recoverAccessForm">
                                    <span>Continue</span>
                                </v-btn>
                            </v-card-actions>
                        </v-row>
                    </v-form>
                </v-card>

                <v-card elevation="6" class="px-10 pb-5 pt-8" v-show="error">
                    <v-row justify="center" class="pb-5">
                        <p class="headline font-weight-light text-center red--text">We had trouble sending you an email</p>
                    </v-row>
                    <v-row justify="center" class="pb-5">
                        <v-btn elevation="6" class="blue white--text" @click="editForm">
                            <span>Try again</span>
                        </v-btn>
                    </v-row>
                </v-card>

                <v-card elevation="6" class="px-10 pb-5 pt-8" v-show="unknownError">
                    <v-row justify="center" class="pb-5">
                        <p class="headline font-weight-light text-center red--text">Something unexpected happened and we are not able to continue processing your request.</p>
                    </v-row>
                    <v-row justify="center" class="pb-5">
                        <p><a href="https://cryptium.com/contact/">Contact support</a></p>
                    </v-row>
                </v-card>

                <v-card elevation="6" class="px-10 pb-5 pt-8" v-show="emailVerification">
                    <v-row justify="center" class="">
                        <p class="headline font-weight-light text-center">Look for our email and click the link</p>
                    </v-row>
                    <v-row justify="center" class="py-5" v-show="isResendEmailReady">
                        <v-btn elevation="6" class="blue white--text" @click="editForm">
                            <span class="ml-2">Didn't get the email?</span>
                        </v-btn>
                    </v-row>
                </v-card>

            </v-col>
        </v-row>
        </v-col>
    </SectionLayout>
</template>

<script>
import { mapState } from 'vuex';
import { isValidEmail } from '@/sdk/input';
import { Client } from '@/client';
import SectionLayout from '@/components/SectionLayout.vue';

const client = new Client();

export default {
    components: {
        SectionLayout,
    },
    data() {
        return {
            recoverAccessForm: null,
            email: null,
            displayInputForm: false,
            emailVerification: false,
            isResendEmailReady: false,
            emailRules: [
                (v) => !!v || 'Email address is required',
                (v) => !v || isValidEmail(v) || 'Email address is required',
            ],
            error: false,
            unknownError: false,
            interactionId: null,
            loading: false,
        };
    },

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },

    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    methods: {
        async init() {
            try {
                this.loading = true;
                if (this.interactionId) {
                    const interaction = await client.interaction.get(this.interactionId);
                    if (interaction && interaction.state && interaction.type === 'recover_access') {
                        if (interaction.state.isVerified) {
                            // email is verified, ask server for next step
                            this.$store.commit('loading', { inputRecoverAccess: true });
                            const response = await client.user.inputRecoverAccess({ interactionId: this.interactionId });
                            console.log(`recover access next step: ${JSON.stringify(response)}`);
                            this.$store.commit('loading', { inputRecoverAccess: true });
                            if (!response || response.error || !response.step) {
                                console.log('recover access: cannot proceed to next step');
                                this.unknownError = true;
                                return;
                            }
                            switch (response.step) {
                            case 'login':
                                // last step is login to register the new key
                                this.$router.push({ path: '/login', query: { mode: 'activate-loginshield', i: this.interactionId } });
                                return;
                            default:
                                this.unknownError = true;
                                break;
                            }
                        }
                    }
                    this.loading = false;
                } else {
                    this.displayInputForm = true;
                    this.loading = false;
                }
            } catch (err) {
                console.log('recover access: failed', err);
                this.error = true;
                this.loading = false;
            }
        },
        editForm() {
            this.error = false;
            this.displayInputForm = true;
            this.emailVerification = false;
            this.$nextTick(() => this.$refs.emailInputRef.focus());
        },
        async recoverAccess() {
            this.error = false;
            console.log('recoverAccess');
            const request = {
                email: this.email,
                nextInteractionId: this.interactionId, // will be present if user arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the user to an appropriate location after access is recovered
            };
            this.$store.commit('loading', { startRecoverAccess: true });
            const response = await client.user.startRecoverAccess(request);
            this.$store.commit('loading', { startRecoverAccess: false });
            console.log('recoverAccess response: %o', response);
            const { isSent } = response;
            if (isSent) {
                this.verifyEmail();
            } else {
                this.displayError();
            }
        },
        validateRecoverAccess() {
            if (this.$refs.recoverAccessFormRef.validate()) {
                this.recoverAccess();
            }
        },
        verifyEmail() {
            this.displayInputForm = false;
            this.emailVerification = true;
            this.error = false;
            const waitBeforeFirstCheckStatus = 10 * 1000; // 10 seconds
            setTimeout(() => { this.isResendEmailReady = true; }, waitBeforeFirstCheckStatus);
        },
        displayError() {
            this.error = true;
            this.displayInputForm = false;
            this.emailVerification = false;
        },
    },

    created() {
        console.log('created');
    },

    mounted() {
        console.log('mounted');
        this.interactionId = this.$route.query.i;
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
